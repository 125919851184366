<template>
<b-overlay :show="isLoading" opacity="0.70" rounded="sm">
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <b-img
            src="@/assets/images/logo/edubotlong.png"
            alt="logo"
            height="50rem"
            width="185rem"
            class="mt-1"
          />
        </b-link>
        <div>
          <b-card-title class="mb-2 text-center">
            Bem-Vindo ao EduBot 🤖
          </b-card-title>

          <div>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label>Usuário de acesso</label>
                <small>
                  <b-link
                    v-b-popover.hover.right="'Os alunos poderão realizar o acesso ao sistema utilizando o seu CGM. Os professores poderão realizar o acesso ao sistema utilizando o seu CPF.'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    title="Central de ajuda"
                  >
                    <b-badge pill variant="light-dark" style="margin-bottom: 0.2rem;">
                      ?
                    </b-badge>
                  </b-link>
                </small>
              </div>
              <b-form-input
                placeholder="Usuário de acesso"
                v-model="email"
                size="sm"
              />
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label>Senha</label>
                <b-link :to="{ path: '/recuperacao'}">
                  <small>Esqueceu sua senha?</small>
                </b-link>
              </div>
              <b-form-input
                type="password"
                placeholder="Senha"
                v-model="password"
                size="sm"
                v-on:keyup.enter="login"
              />
            </b-form-group>
            
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              class="mt-1"
              size="sm"
              @click="login"
            >
              Login
            </b-button>
          </div>      

          <div class="divider my-2">
            <div class="divider-text">
              Ou
            </div>
          </div>
          
          <!-- social button -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="outline-dark"
              pill
              block
              class="mt-1"
              size="sm"
              @click="loginGoogle"
            >
              <b-img
                src="@/assets/images/logo/google.png"
                alt="logo"
                height="20px"
                width="20px"
                class="mr-1"
              />
              Entrar utilizando o Google
            </b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</b-overlay>
</template>

<script>
/* eslint-disable */
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import { initialAbility } from '../../libs/acl/config'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: null,
      password: null,

      emailByGoogle: null,

      isLoading: false,
    }
  },
  computed: {
  },
  methods: {

    async loginGoogle(){
      const googleUser = await this.$gAuth.signIn();
      const payload = {
        userDetails: {
          email: googleUser.xu.lv,
        },
      };
      this.$store
        .dispatch("auth/loginByGoogle", payload)
        .then((response) => {
          const userData = JSON.parse(localStorage.getItem('userData'))
          let newAbilities = initialAbility.concat(userData.ability)
          this.$ability.update(newAbilities)
          this.$router.push('/fases')
          this.errorLogin = false
        })
        .catch((error) => {
          this.errorLogin = true
          this.isLoading = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    login() {
      this.isLoading = true;
      const payload = {
        userDetails: {
          username: this.email,
          password: this.password,
        },
      };
      this.$store
        .dispatch("auth/login", payload)
        .then((response) => {
          const userData = JSON.parse(localStorage.getItem('userData'))
          let newAbilities = initialAbility.concat(userData.ability)
          this.$ability.update(newAbilities)
          this.$router.push('/fases')
          this.errorLogin = false
        })
        .catch((error) => {
          this.errorLogin = true
          this.isLoading = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
